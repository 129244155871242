export const DEFAULT_CLASSES: string = 'text-base';

export const BUTTON_STYLE: Record<string, string> = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const BUTTON_CLASSES: Record<string, string> = {
  [BUTTON_STYLE.PRIMARY]: ['dark:bg-white', 'dark:hover:bg-gray-100', 'dark:text-black'].join(' '),
  [BUTTON_STYLE.SECONDARY]: ['dark:border-gray-600', 'dark:border'].join(' '),
  [BUTTON_STYLE.TERTIARY]: ['bg-inherit', 'dark:bg-inherit', 'text-black', 'dark:text-white', 'hover:bg-gray-100'].join(
    ' '
  ),
};
