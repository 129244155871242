import type { StatusScoreRange } from '$lib/types/project';

export const JUPYTER_HUB_ROUTE = '/hub/home';

export const SOCKET_ERROR_MESSAGES = {
  outOfCredit: "Oops! Looks like you're out of credit. Please contact the administrator to continue the conversation.",
  connectionFailed: 'Connecting to chatbot failed. If connection is not established soon, please contact admin.',
  unknown: 'Oops! Looks like something went wrong. Please contact the administrator.',
};

export const SOCKET_RESPONSE_CODES = {
  OK: 200,
  NO_CREDIT: 402,
  BAD_REQUEST: 400,
  USER_NOT_FOUND: 404,
  SERVER_ERROR: 400,
};

export const DEFAULT_MARKDOWN_LANGUAGE = 'python';

export const DEFAULT_CHAT_LENGTH = 16;

export const AI_MESSAGE_TYPE = 'AI';

export const USER_MESSAGE_TYPE = 'User';

export const UNRECORDED_VALUE = 'unrecorded';

export const USER_PROFILE_FETCH_COOLDOWN_SECONDS = 120;

export const WEB_SOCKET_RECONNECT_COOLDOWN_SECONDS = 5;

export const PREFERRED_DARK_MEDIA_THEME = '(prefers-color-scheme: dark)';

export const TASK_UPDATED_EVENT = 'task-updated';
export const TASK_DISPLAY_DURATION = 10000;

export const SymPermissions = {
  CAN_USE_MESSAGE_THREAD_FILES: 'sym.can_use_message_thread_files',
} as const;

export const FEEDBACK: Record<string, string> = {
  LIKED: 'liked',
  DISLIKED: 'disliked',
  UNRECORDED: 'unrecorded',
  UNDO_LIKED: 'undo_liked',
  UNDO_DISLIKED: 'undo_disliked',
};

export const PROJECT_STATUS: Record<string, { text: string; color: string; statusScoreRange: StatusScoreRange }> = {
  'On track': { text: 'On track', color: 'green', statusScoreRange: { minStatusScore: 2 / 3, maxStatusScore: 1 } },
  Caution: { text: 'Caution', color: 'yellow', statusScoreRange: { minStatusScore: 1 / 3, maxStatusScore: 2 / 3 } },
  Blocked: { text: 'Blocked', color: 'red', statusScoreRange: { minStatusScore: 0, maxStatusScore: 1 / 3 } },
};

export const NOTIFICATION_INFO: Record<
  string,
  { description: string; owner: 'assignedBy' | 'unassignedBy' | 'commentedBy' | 'changedBy' | 'deletedBy' }
> = {
  'stories.assign': { description: 'assigned you the story', owner: 'assignedBy' },
  'stories.unassign': { description: 'unassigned you from the story', owner: 'unassignedBy' },
  'story_comment.create': { description: 'commented on the story', owner: 'commentedBy' },
  'stories.status_change': { description: 'changed the status of the story', owner: 'changedBy' },
  'stories.delete': { description: 'deleted the story', owner: 'deletedBy' },
};

export namespace TaigaPermissions {
  export const CAN_USE_CUSTOM_FILTERS = 'can_use_custom_filters';
  export const CAN_ACCESS_PROJECT_FILES = 'can_access_project_files';
  export const CAN_ACCESS_PROJECT_NOTES = 'can_access_project_notes';
  export const CAN_ACCESS_PROJECT_OVERVIEW = 'can_access_project_overview';
  export const CAN_ACCESS_PROJECT_ACTIONS = 'can_access_project_actions';
  export const CAN_ACCESS_PROJECT_REPORTS = 'can_access_project_reports';
  export const CAN_ACCESS_PROJECT_MEMBERS = 'can_access_project_members';
  export const CAN_ACCESS_PROJECT_BOARD = 'can_access_project_board';
  export const ACCESS_PROJECT_NOTES = 'access_projects_notes';
  export const ACCESS_PROJECT_PROGRESS = 'access_projects_progress';
  export const ACCESS_PROJECT_STATUS = 'access_projects_status';
  export const ACCESS_PROJECT_STUDENT_NAME = 'access_projects_student_name';
  export const ACCESS_PROJECTS_GENERAL_OVERVIEW = 'access_projects_general_overview';
}
