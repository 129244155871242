<script lang="ts">
  /*
  We went with the naming convention of using PM prefix as a shortcut,
  when transitioning from Flowbite components to our components,
  if possible use naming convention where first letter of the component will be capitalized.
  
  Example: Button
  
  By using PM prefix we are indicating that the component will be used only in the PM Tool
  service which is not true. We intend to create a package of our components that will be
  used in all services across our APP: PMTool, Sym..
  */

  import { Button } from 'flowbite-svelte';
  import { createEventDispatcher } from 'svelte';
  import { BUTTON_CLASSES, BUTTON_STYLE, DEFAULT_CLASSES } from './PMButton';

  export let text = '';
  export let classes = '';
  export let style = BUTTON_STYLE.PRIMARY;
  export let disabled = false;

  const dispatch = createEventDispatcher();
</script>

<Button
  color="dark"
  {disabled}
  class={`${DEFAULT_CLASSES} ${BUTTON_CLASSES[style]} ${classes}`}
  on:click={() => dispatch('click')}>{text}</Button
>
